// 
// Widgets.scss
// 

.mini-stats-wid{
    .mini-stat-icon{
        overflow: hidden;
        position: relative;
        &:before, &:after{
            content: "";
            position: absolute;
            width: 8px;
            height: 54px;
            background-color: rgba($white,.1);
            left: 16px;
            transform: rotate(32deg);
            top: -5px;
            transition: all 0.4s;
        }

        &::after{
            left: -12px;
            width: 12px;
            transition: all 0.2s;
        }
    }

    &:hover{
        .mini-stat-icon{
            &::after{
                left: 60px;
            }
        }
    }
}


/* Activity */

.activity-feed {
    list-style: none;

    .feed-item {
        position: relative;
        padding-bottom: 27px;
        padding-left: 16px;
        border-left: 2px solid $light;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 9px;
            left: -7px;
            width: 12px;
            height: 12px;
            border-radius: 2px;
            border: 2px solid $primary;
            background-color: $card-bg;
        }

        &:last-child {
            border-color: transparent;
        }
    }
}


