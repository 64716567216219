

/* ==============
  Calendar
===================*/

.lnb-calendars-item{
  display: inline-block;
  margin-right: 7px;
}

input[type=checkbox].tui-full-calendar-checkbox-round+span{
  margin-right: 4px /*rtl: 0*/;
  margin-left: 0 /*rtl: 4px*/;
}

.tui-full-calendar-layout, .tui-full-calendar-timegrid-timezone{
  background-color: $card-bg !important;
}

.tui-full-calendar-dayname-container, 
.tui-full-calendar-left, 
.tui-full-calendar-splitter,
.tui-full-calendar-time-date,
.tui-full-calendar-weekday-grid-line,
.tui-full-calendar-timegrid-timezone,
.tui-full-calendar-timegrid-gridline{
  border-color: $gray-300 !important;
}

.tui-full-calendar-weekday-exceed-in-week{
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 4px;
  background-color: $card-bg;
  color: $body-color;
  border-color: $border-color;
}

.tui-full-calendar-timegrid-hour{
  color: $body-color !important;
}

.tui-full-calendar-weekday-schedule-title, .tui-full-calendar-time-schedule{
  font-weight: $font-weight-semibold;
}


/* ==============
  Calendar
===================*/

.fc {
  .fc-toolbar {
      h2 {
          font-size: 16px;
          line-height: 30px;
          text-transform: uppercase;
      }

      @media (max-width: 767.98px) {

          .fc-left,
          .fc-right,
          .fc-center {
              float: none;
              display: block;
              text-align: center;
              clear: both;
              margin: 10px 0;
          }

          >*>* {
              float: none;
          }

          .fc-today-button {
              display: none;
          }
      }

      .btn {
          text-transform: capitalize;
      }

  }
}

.fc {
  th.fc-widget-header {
      background: $gray-300;
      color: $gray-700;
      line-height: 20px;
      padding: 10px 0;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
  }
}

.fc-unthemed {

  .fc-content,
  .fc-divider,
  .fc-list-heading td,
  .fc-list-view,
  .fc-popover,
  .fc-row,
  tbody,
  td,
  th,
  thead {
      border-color: $gray-300;
  }

  td.fc-today {
      background: lighten($gray-200, 2%);
  }
}

.fc-button {
  background: $card-bg;
  border-color: $border-color;
  color: $gray-700;
  text-transform: capitalize;
  box-shadow: none;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: $primary;
  color: $white;
  text-shadow: none;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-event,
.fc-event-dot {
  background-color: $primary;
}

.fc-event .fc-content {
  color: $white;
}

#external-events {
  .external-event {
      text-align: left !important;
      padding: 4px;
  }
}
